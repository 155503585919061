<template>
<div class="fill-height">    
    <div>
        <v-app-bar
            flat
            :app="false"
            :absolute="false"
            :fixed="false"
            :hide-on-scroll="false"
            height="90"      
            class="app-header"
            >

            <div style="position:relative; cursor:pointer; max-width:128px; min-width: 76px; display: flex; align-self: baseline; margin-top: -4px" @click="$router.push({name:'Home'})">
                <img alt="i-ACT Logo" :src="EnLogo" style="position:relative; width: 100%;"/>
            </div>       
        </v-app-bar>
    </div>
    <v-row no-gutters class="text-center justify-center align-center bgcolor-brand-pink fill-height">
        <div style="max-width:900px" class="white--text px-16">
            <h2 class="text-h5">Thank you for visiting i-act.ca. This program is no longer active. For any inquiries or support, please contact us at support@i-act.ca.</h2>
            <p class="text-subtitle-1"><hr></p>
            <h2 class="text-h5">Merci de visiter i-act.ca. Ce programme n'est plus actif. Pour toute question ou assistance, veuillez nous contacter à support@i-act.ca.</h2>
        </div>
    </v-row>                
</div>    
</template>

<script>
import EnLogo from '@/assets/trap-logo.svg'
import FrLogo from '@/assets/trap-logo-fr.svg'

export default {
    computed: {
        EnLogo(){
            return EnLogo
        },
        FrLogo(){
            return FrLogo
        },
    }
}
</script>

<style lang="scss" scoped>
.app-header{
    background-color: $gray !important;
    z-index: 1; 
}
@media #{map-get($display-breakpoints, 'sm-and-down')}{
.app-header{
    //line-height:1;
    font-size:12px;
}
}
.header-col, .app-bar-item{
    align-self: center;
    text-align: center;
    display: contents;
}
a{
    text-decoration: none;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    color: white !important;
    font-weight: bold;
    font-size: 1em;
}
a:hover{
    color: $yellow !important;
}
a.active{
    color: $yellow !important;
}

.custom-green{
    background-color: $green;
}
.header-green{
    background-color: $green;
    color: white;
    font-weight: bold;
    padding: 25px;
}
::v-deep .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
}

.desktop{
    display: inline-block;
}

.mobile{
    display: none;
}

@media only screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;;
  }
}
</style>