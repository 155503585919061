import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cache from "./plugins/cache.js";
import formRulesAPI from "./plugins/formRulesAPI.js";
import vuetify from './plugins/vuetify'
import JsonViewer from "vue-json-viewer";
import axios from 'axios'
import Home from '@/views/Home'
import commonComponents  from "./components/Globals/loader"

const site_shutdown_flag = store.state.site_shutdown_flag
if (site_shutdown_flag===true){
        let code = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
  
    if(location.hostname=="localhost"){
        window.code = code;
    }
}
else {
    async function initCampaignRoutes(callBack){

      function initRoute(route){
        return {
          path: `/${route.vanity}`,
          name: `${route.vanity}`,
          component: Home,
          meta: {
            public: true,
            allowed: '*',
            campaign: route
          }
        }
      }

      let URL = process.env.VUE_APP_API+'/campaign/list'

      axios.get(URL).then((response)=>{
        let routes = response.data
        for(let r=0; r<routes.length; r++){
          let route = routes[r]
          let record = initRoute(route)
          router.addRoute(record)
        } 

        if(callBack){
          callBack()
        }


      })


    }
    Vue.use(JsonViewer)

    commonComponents.forEach(component => {
      Vue.component(component.name, component);
    }); 
    Vue.config.productionTip = false

    initCampaignRoutes(()=>{
      let code = new Vue({
        created: function(){
          Vue.prototype.cache = cache
          Vue.prototype.formRulesAPI = formRulesAPI
        },
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount('#app')

      if(location.hostname=="localhost"){
        window.code = code;
      }
    })
}