import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//const platform_expiry_date = "2024-09-21 23:59:59"
const platform_expiry_date = "2024-09-27 23:59:59"

export default new Vuex.Store({
  state: {
    siteBlocker: false,
    debug: false,
    platform_expiry_date: platform_expiry_date,
    accreditation_expired: new Date() > new Date(platform_expiry_date),
    site_shutdown_flag: true,
    controller: {
      UserController: {
        authorized:()=>{return false}
      },
      LabelController: undefined,
      VideoController: undefined,
      // PledgeController: undefined,
      CreditController: undefined,
      SearchController: undefined,
      LanguageController: undefined,
      QuestionSetController: undefined,
      CampaignController: undefined
    },
    lastLogin: null
  },
  getters: {
    isSiteShutdown: function(state){
        return state.site_shutdown_flag
    },
    accreditation_expired: function(state){
        return state.accreditation_expired
    },
    platform_expiry_date: function(state){
        return state.platform_expiry_date
    },
    siteBlocker: function(state){
      return state.siteBlocker
    },
    controllers(state){
      return state.controller
    },
    debug(state){
      return state.debug
    },
    UserController: function(state){
      return state.controller.UserController
    },
    LabelController: function(state){
      return state.controller.LabelController
    },
    VideoController: function(state){
      return state.controller.VideoController
    },
    // PledgeController: function(state){
    //   return state.controller.PledgeController
    // },
    CreditController: function(state){
      return state.controller.CreditController
    },
    SearchController: function(state){
      return state.controller.SearchController
    },
    LanguageController: function(state){
      return state.controller.LanguageController
    },
    QuestionSetController: function(state){
      return state.controller.QuestionSetController
    },
    CampaignController: function(state){
      return state.controller.CampaignController
    },
    lastLogin: function(state){
      return state.lastLogin
    }
  },
  mutations: {
    debug: function(state, payload){
      state.debug = payload
    },
    UserController: function(state, payload){
      state.controller.UserController = payload
    },
    LabelController: function(state, payload){
      state.controller.LabelController = payload
    },
    VideoController: function(state, payload){
      state.controller.VideoController = payload
    },
    // PledgeController: function(state, payload){
    //   state.controller.PledgeController = payload
    // },
    CreditController: function(state, payload){
      state.controller.CreditController = payload
    },
    SearchController: function(state, payload){
      state.controller.SearchController = payload
    },
    LanguageController: function(state, payload){
      state.controller.LanguageController = payload
    },
    QuestionSetController: function(state, payload){
      state.controller.QuestionSetController = payload
    },
    CampaignController: function(state, payload){
      state.controller.CampaignController = payload
    },
    lastLogin: function(state, payload){
      state.lastLogin = payload
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload;
    },
  },
  actions: {
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },      
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    LabelController: function(context, payload){
      context.commit('LabelController',payload)
    },
    UserController: function(context, payload){
      context.commit('UserController',payload)
    },
    VideoController: function(context, payload){
      context.commit('VideoController',payload)
    },
    // PledgeController: function(context, payload){
    //   context.commit('PledgeController',payload)
    // },
    CreditController: function(context, payload){
      context.commit('CreditController',payload)
    },
    SearchController: function(context, payload){
      context.commit('SearchController',payload)
    },
    LanguageController: function(context, payload){
      context.commit('LanguageController',payload)
    },
    QuestionSetController: function(context, payload){
      context.commit('QuestionSetController',payload)
    },
    CampaignController: function(context, payload){
      context.commit('CampaignController',payload)
    },
    lastLogin: function(context, payload){
      context.commit('lastLogin',payload)
    }
  },
  modules: {
  }
})
