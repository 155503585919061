<template>
<v-container fluid class="app-footer">
    <v-row justify="space-around" align="center" :class="['py-3', {'flex-column':$vuetify.breakpoint.xsOnly}]">
        <v-col class="d-flex justify-center px-1 text-center">
            <a href="http://ctccomm.com" target="_blank" v-html="ctc_copyrights_label"/>            
        </v-col>           
    </v-row>            
</v-container>
</template>
  
<script>
export default {
  data(){
    return {
        ctc_copyrights_label: "<u>CTC Communications</u>&nbsp;&copy;&nbsp;" + new Date().getFullYear()+". All Rights Reserved"
    }
  },
}
</script>
  
<style lang="scss" scoped>
.app-footer{
    background-color: $gray;
    color: white;
    font-size: 16px;
}
a{
    text-decoration: none;
    margin: 5px;
    color: white !important;
}

.link:hover,
a:hover{
    color: $yellow !important;
}

.link:hover,
a.active{
    color: $yellow !important;
}

@media only screen and (max-width: 374px) {
.app-footer{
    font-size: 13px;
}
}
</style>
  
  
  
  